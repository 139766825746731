<template>
  <div>
    <div class="row">
      <div class="col-lg-6">

        <div class="form-group ">
          <input type="text" 
          id="payment-card-number" 
          v-model="cari"
          class="form-control" placeholder="search Menu">
        </div>
      </div>
      <div class="col-lg-4">
      </div>

      <div class="col-lg-2">
        <b-button class="btn-primary mb-1" block   v-b-modal.add-menu
          variant="outline-primary">
          <i class="fa fa-plus"></i>
          Add Menu
        </b-button>
      </div>
    </div>

    <MenuList></MenuList>
    <AddMenu  @get_menu = loadData ></AddMenu>  

  </div>
</template>

<script>
  import {
    mapMutations,
    mapState
  } from 'vuex'
  import Base from '@/config/Mixins_base';
  import axios from '../../../config/Axios';
  
  import Ripple from 'vue-ripple-directive'
  import {
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BButton,
    VBModal,
     

  } from 'bootstrap-vue'
import MenuList from './component/MenuList.vue'
import AddMenu from './component/AddMenu.vue'

  export default {
    data() {
      return {
        selected: 'default',
        currentPage: 1,
        rows: 100,
        cari : '', 
      }
    },
    mixins: [Base],

    components: {
      BDropdown,
      BDropdownItem,
      BPagination,
      BModal,
      BButton,
      VBModal, 
      MenuList,
        AddMenu, 
    },
    directives: {
    'b-modal': VBModal,
    Ripple,
  }, 
  computed:{
    ...mapState({
       rs_menu: state => state.aclMenu.rs_menu,
      })
  },
  watch:{
      cari(newData, old) {
      if (newData != NaN) { 
         this.loadData( );
      }
    }
    },
    mounted(){
      this.loadData();
    },
  methods:{
    ...mapMutations({
      set_rs_menu: 'set_rs_menu',
      }),
    

      async loadData(offset, cari)  {
          const self = this;
          await axios({
            method: 'PUT',
            url: '/api/acl/menu/list',
            data: {
              offset: self.currentPage,
              cari: self.cari
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
            })
            .then(function (response) { 
            self.set_rs_menu(response.data.result);  
            }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
            });
          },
  }

  }
</script>

<style>

</style>