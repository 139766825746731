<template>
  <div class="center">
    <b-modal id="edit-sub-menu" hide-footer no-close-on-backdrop content-class="shadow" title="Tambah Menu"
      ok-title="Accept">
      <validation-observer ref="simpleRules">
        <b-form> 
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label>Nama Menu  </label>
                <validation-provider #default="{ errors }" name="Nama Menu">
                  <b-form-input v-model="menuData.nm_menu" :state="errors.length > 0 ? false:null"
                    placeholder="Nama Menu" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>Nama Sub Menu</label>
                <validation-provider #default="{ errors }" name="Nama Menu">
                  <b-form-input v-model="SubMenuData.nm_menu" :state="errors.length > 0 ? false:null" placeholder="Nama Sub Menu" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>Url</label>
                <validation-provider #default="{ errors }" name="Url" rules="required">
                  <b-form-input v-model="SubMenuData.url" :state="errors.length > 0 ? false:null" type="text"
                    placeholder="Url Link / " />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>Order</label>
                <validation-provider #default="{ errors }" name="order" rules="required">
                  <b-form-input v-model="SubMenuData.order" :state="errors.length > 0 ? false:null" type="number"
                    placeholder="Order" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <label class="m-0">Status</label>
              <div class="demo-inline-spacing">
                <b-form-radio v-model="SubMenuData.st" name="st" value="1">
                  Enable
                </b-form-radio>
                <b-form-radio v-model="SubMenuData.st" name="st" value="0">
                  Disable
                </b-form-radio>
              </div>
            </b-col>
            <hr>
            <b-col cols="6">
              <b-button class="mb-3 mt-3" variant="outline-primary" block type="submit" @click.prevent="act_simpan">
                <i class="fa fa-save mr-1"></i>Simpan
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button class="mb-3 mt-3" variant="outline-warning" block @click="$bvModal.hide('edit-sub-menu')">
                <i class="fa fa-close mr-1"></i> Keluar</b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
  import axios from '@/config/Axios';
  import Base from '@/config/Mixins_base';
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required
  } from '@validations'
  import {
    BFormRadio,
    BModal,
    BButton,
    VBModal,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BCardText,

  } from 'bootstrap-vue'
  import vSelect from 'vue-select'

  export default {
    props: {
      menuData :{},
      SubMenuData : {}

    },
    data: () => ({
      required,
      add: {}

    }),
    mixins: [Base],
    emits: ["get_menu"],
    components: {
      BFormRadio,
      BModal,
      BButton,
      VBModal,
      ValidationProvider,
      ValidationObserver,
      BFormInput,
      BFormGroup,
      BForm,
      BRow,
      BCol,
      BCardText,
      vSelect,
    },
    methods: { 
      async act_add() {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/acl/menu/update_sub',
            data: {
              id_menu: self.menuData.id_menu,
              nm_menu: self.SubMenuData.nm_menu,
              order: self.SubMenuData.order,
              st: self.SubMenuData.st,
              url: self.SubMenuData.url,
              id_menu_sub : self.SubMenuData.id_menu_sub
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rs = response.data.result;
            self.notification('primary', "info", response.data.message);
            self.$emit("get_menu", "");
            self.add = {};
          }).catch(err => {
            self.notification('warning', "Error", err.message);
          });
      },
      act_simpan() {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$root.$emit('bv::hide::modal', 'edit-sub-menu')
            this.$root.$emit('bv::hide::modal', 'sub-menu-list')
            this.act_add()
          }
        })
      },
    },

  }
</script>