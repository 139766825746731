<template>
    <div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12" v-for="menu in rs_menu.rs" v-bind:key="menu.id_menu">
                <div class="card card-apply-job">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                            <div class="media">

                                <div class="media-body">
                                    <h4><i :class="'fa'  + menu.icon + 'mr-1'"></i> {{menu.nm_menu}}</h4>
                                    <small class="text-muted"> {{menu.url}}</small>
                                </div>
                            </div>
                            <div class="badge d-block badge-primary" v-if="menu.st =='1'">
                                <span><i class="fa fa-check mr-1"></i>Enable</span>
                            </div>
                            <div class="badge d-block badge-warning" v-if="menu.st =='0'">
                                <span><i class="fa fa-warning mr-1"></i>Disable</span>
                            </div>
                        </div>

                        <div class="apply-job-package bg-light-primary rounded p-1">
                            <div>
                                <h2 class="d-inline mr-25 mr-1">{{menu.jm_sub}}</h2>
                                <sub class="text-body"><small>Sub Menu</small></sub>
                            </div>
                            <div v-if="menu.jm_sub !='0'" @click="subMenuList(menu)" class="btn btn-primary  btn-sm"
                                block   v-b-modal.sub-menu-list><i
                                    class="fa fa-list"></i> Sub Menu List</div>

                            <div v-if="menu.jm_sub =='0'" @click="addSubMenu(menu)" class="btn btn-info  btn-sm" block
                                  v-b-modal.add-sub-menu><i
                                    class="fa fa-plus"></i> Add Sub Menu </div>

                        </div>
                        <div class="row  ">
                            <div class="col-lg-6 mb-1">
                                <button type="button" block  
                                    @click="edit_menu(menu)" v-b-modal.edit-menu
                                    class="btn btn-outline-warning btn-block waves-effect"><i
                                        class="fa fa-edit mr-1"></i>Edit</button>
                            </div>
                            <div class="col-lg-6 mb-1">
                                <button type="button" @click="deleteMenu(menu.id_menu)"
                                    class="btn btn-outline-danger btn-block waves-effect"><i
                                        class="fa fa-trash mr-1"></i>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-6">
                <i class="fa fa-list"></i> Total Row {{ rs_menu.lenght}}
            </div>
            <div class="col-lg-6">
                <b-pagination v-model="currentPage" :total-rows=" rs_menu.lenght" per-page='6' base-url="#"
                    :align="'right'" />
            </div>
        </div>

        <SubMenuList :data_menu = data_menu  @get_menu = loadData></SubMenuList>
        <EditMenu :data_menu=data_menu></EditMenu>
        <AddSubMenu :data_menu = data_menu @get_menu = loadData></AddSubMenu> 
    </div>
</template>

<script>
    import EditMenu from './EditMenu.vue'
    import SubMenuList from './submenu/SubMenuList.vue' 
    import AddSubMenu from './submenu/AddSubMenu.vue'
    import Base from '@/config/Mixins_base';
    import Ripple from 'vue-ripple-directive';
    import axios from '@/config/Axios';
    import {
        mapMutations,
        mapState
    } from 'vuex'

    import {
        BPagination,
        BModal,
        BButton,
        VBModal,
        BDropdown,
        BDropdownItem
    } from 'bootstrap-vue'

    export default {

        data: () => ({
            selected: 'default',
            currentPage: 1,
            rows: 0,
            data_menu: {}
        }),
        mixins: [Base],
        components: {
            BDropdown,
            BDropdownItem,
            BPagination,
            BModal,
            BButton,
            VBModal,
            EditMenu,
            SubMenuList, 
            AddSubMenu,
        },
        computed: {
            ...mapState({
                rs_menu: state => state.aclMenu.rs_menu,
            })
        },
        mounted() {
            this.rows = this.rs_menu.lenght
        },
        watch: {
            currentPage(newcurrentPage, oldcurrentPage) {
                if (newcurrentPage) {
                    this.loadData();
                }
            }
        },
        methods: {
            ...mapMutations({
                set_rs_menu: 'set_rs_menu',
            }),
            async loadData() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/acl/menu/list',
                        data: {
                            offset: self.currentPage,
                            cari: self.cari
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_rs_menu(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async deleteMenu(id_menu) {
                if (confirm("Apakan Anda yakin akan menghapus data ini..?")) {
                    const self = this;
                    await axios({
                            method: 'POST',
                            url: '/api/acl/menu/delete',
                            data: {
                                id_menu: id_menu
                            },
                            headers: {
                                'Authorization': self.isAuthenticated
                            }
                        })
                        .then(function (response) {
                            self.notification('info', "200", response.data.message);
                            self.loadData();
                        }).catch(err => {
                            self.pesan = err.message;
                            self.notification('warning', "Error", err.message);
                        });
                }
            },
            edit_menu(menu) {
                this.data_menu = menu;
            },
            addSubMenu(menu){
                this.data_menu = menu;
            },
            subMenuList(menu){
                this.data_menu = menu;
            }
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
    }
</script>

<style>

</style>