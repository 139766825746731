var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center"},[_c('b-modal',{attrs:{"id":"edit-menu","hide-footer":"","no-close-on-backdrop":"","content-class":"shadow","title":"Tambah Menu","ok-title":"Accept"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Nama Menu")]),_c('validation-provider',{attrs:{"name":"Nama Menu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nama Menu"},model:{value:(_vm.data_menu.nm_menu),callback:function ($$v) {_vm.$set(_vm.data_menu, "nm_menu", $$v)},expression:"data_menu.nm_menu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Url")]),_c('validation-provider',{attrs:{"name":"Url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"Url Link / "},model:{value:(_vm.data_menu.url),callback:function ($$v) {_vm.$set(_vm.data_menu, "url", $$v)},expression:"data_menu.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("icon")]),_c('validation-provider',{attrs:{"name":"icon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"icon"},model:{value:(_vm.data_menu.icon),callback:function ($$v) {_vm.$set(_vm.data_menu, "icon", $$v)},expression:"data_menu.icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Order")]),_c('validation-provider',{attrs:{"name":"order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","placeholder":"icon"},model:{value:(_vm.data_menu.order),callback:function ($$v) {_vm.$set(_vm.data_menu, "order", $$v)},expression:"data_menu.order"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"m-0"},[_vm._v("Order")]),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"st","value":"1"},model:{value:(_vm.data_menu.st),callback:function ($$v) {_vm.$set(_vm.data_menu, "st", $$v)},expression:"data_menu.st"}},[_vm._v(" Enable ")]),_c('b-form-radio',{attrs:{"name":"st","value":"0"},model:{value:(_vm.data_menu.st),callback:function ($$v) {_vm.$set(_vm.data_menu, "st", $$v)},expression:"data_menu.st"}},[_vm._v(" Disable ")])],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"m-0"},[_vm._v("View")]),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"view","value":"1"},model:{value:(_vm.data_menu.views),callback:function ($$v) {_vm.$set(_vm.data_menu, "views", $$v)},expression:"data_menu.views"}},[_vm._v(" View ")]),_c('b-form-radio',{attrs:{"name":"view","value":"0"},model:{value:(_vm.data_menu.views),callback:function ($$v) {_vm.$set(_vm.data_menu, "views", $$v)},expression:"data_menu.views"}},[_vm._v(" Hidden ")])],1)]),_c('hr'),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-3 mt-3",attrs:{"variant":"outline-primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.act_simpan($event)}}},[_c('i',{staticClass:"fa fa-save mr-1"}),_vm._v("Simpan ")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-3 mt-3",attrs:{"variant":"outline-warning","block":""},on:{"click":function($event){return _vm.$bvModal.hide('edit-menu')}}},[_c('i',{staticClass:"fa fa-close mr-1"}),_vm._v(" Keluar")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }